.frappe-card {
  padding: 0;
  background-color: var(--background-color);
}

.navbar-brand-container {
  background-color: var(--background-color);
  position: relative;
  border: 0;
}

.navbar .doc-container .navbar-collapse {
  margin-right: 0;
}

.list-jobs {
  font-size: 14px;
}

.table {
  margin-bottom: 0px;
  margin-top: 0px;
  color: var(--text-color);
}
.table td {
  padding: 14px 20px;
}
.table a {
  color: var(--btn-text-color) !important;
}

thead td {
  border-top: 0 !important;
}

.table th,
.table td {
  margin-top: -0.5px;
}

.worker-name {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.job-name {
  font-size: 13px;
  font-family: "Courier New", Courier, monospace;
}

.background-job-row:hover {
  background-color: #f9fafa;
}

.no-background-jobs {
  min-height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-background-jobs > img {
  margin-bottom: var(15px);
  max-height: 100px;
}

.footer {
  align-items: flex-end;
  margin-top: 15px;
  font-size: 14px;
}

.page-content-wrapper {
  padding: 0 !important;
  margin: 0 0 1rem 0 !important;
}

.all-contributions .table td.message-col {
  width: auto;
}
.all-contributions .table td.status-col {
  width: 15%;
}
.all-contributions .table td.raised-by-col {
  width: auto;
}
.all-contributions .table td.date-col {
  width: 15%;
}
.all-contributions .table .patch-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.all-contributions .table .patch-row:hover {
  background-color: var(--btn-secondary-hover-bg-color);
}
.all-contributions .message-cell {
  font-weight: 550;
}
.all-contributions .message-cell,
.all-contributions .raised-by-cell,
.all-contributions .space-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 0;
}
.all-contributions .action-buttons .btn {
  margin-left: 4px;
  border-radius: 8px;
  padding: 3px 8px;
  font-size: 13px;
  border: none;
}
.all-contributions .action-buttons .btn.btn-default {
  background-color: #e9e9e9;
  color: #000;
}
.all-contributions .action-buttons .btn.btn-default:hover {
  background-color: #dcdcdc;
}
.all-contributions .action-buttons .btn.btn-success {
  background-color: #000;
  color: #fff;
}
.all-contributions .action-buttons .btn.btn-success:hover {
  background-color: #333;
}
.all-contributions .action-buttons .btn.btn-danger {
  background-color: #dc3545;
  color: #fff;
}
.all-contributions .action-buttons .btn.btn-danger:hover {
  background-color: #bb2d3b;
}
.all-contributions .action-buttons .btn:last-child {
  margin-left: 0;
}
.all-contributions .get_patches {
  margin-top: 15px;
}

.diff-content {
  text-wrap: auto;
  color: var(--text-color);
}
.diff-content ins {
  background-color: var(--diff-ins-bg-color);
  color: var(--diff-ins-text-color);
  text-decoration: none;
}
.diff-content del {
  background-color: var(--diff-del-bg-color);
  color: var(--diff-del-text-color);
  text-decoration: none;
}

.review-patch-modal {
  min-height: calc(100vh - 100px);
}
.review-patch-modal .modal-header {
  border-bottom: 1px solid #e9e9e9 !important;
  padding: 1rem 1rem !important;
  display: flex;
  flex-direction: row;
}
@media (max-width: 768px) {
  .review-patch-modal .modal-header {
    flex-direction: column;
  }
}
.review-patch-modal .modal-header .close {
  position: absolute;
  top: 5px;
  left: 8px;
  padding: 0;
  margin: 0;
}
.review-patch-modal .view-toggle-buttons-wrapper {
  display: flex;
  align-items: end;
}
.review-patch-modal .view-toggle-buttons {
  display: flex;
  gap: 6px;
  background-color: var(--view-bg-color);
  padding: 4px;
  width: -moz-max-content;
  width: max-content;
  border-radius: 10px;
  margin-top: 22px;
  margin-left: 14px;
}
.review-patch-modal .view-toggle-buttons .btn {
  padding: 3px 6px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  color: var(--view-btn-text);
  background-color: var(--view-bg-color);
  transition: all 0.2s ease;
}
.review-patch-modal .view-toggle-buttons .btn.active {
  background-color: var(--view-btn-bg-active);
  color: var(--view-btn-text-active);
}
.review-patch-modal .view-toggle-buttons .btn:active {
  background-color: var(--view-btn-bg-active) !important;
  color: var(--view-btn-text-active) !important;
  outline: none !important;
}
.review-patch-modal .modal-title {
  max-width: 100% !important;
}
.review-patch-modal .modal-body {
  padding-top: 18px !important;
}
.review-patch-modal .modal-body .diff-content,
.review-patch-modal .modal-body .preview-content {
  display: none;
  max-width: 100%;
}
.review-patch-modal .modal-body .diff-content.active,
.review-patch-modal .modal-body .preview-content.active {
  display: block;
}
.review-patch-modal .review-modal-footer {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  border-top: 1px solid #e9e9e9;
}
.review-patch-modal .review-modal-footer .btn {
  width: auto !important;
}
.review-patch-modal .review-modal-footer .approve-patch {
  background-color: #000;
  color: #fff;
}
.review-patch-modal .review-modal-footer .reject-patch {
  background-color: #fff;
  color: #000;
  background-color: #f8f8f8;
}

.filters-wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}
.filters-wrapper .space-filter {
  display: flex;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
}
.filters-wrapper .space-filter label {
  font-size: 14px;
  font-weight: 550;
  margin-bottom: 4px;
}
.filters-wrapper .space-filter select {
  position: relative;
  border: 1.5px solid #c7c7c7;
  border-radius: 8px;
  padding: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.filters-wrapper .space-filter select svg {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.frappe-card {
  margin-top: 12px;
}

.contributions-view {
  width: 100%;
  padding: 32px;
  padding-top: 18px;
}
.contributions-view .btn-warning {
  background-color: var(--admin-banner-btn-bg);
  color: var(--admin-banner-btn-text) !important;
}
.contributions-view .btn-warning:hover {
  background-color: var(--admin-banner-btn-hover-bg);
}
.contributions-view .btn-warning:active {
  background-color: var(--admin-banner-btn-active-bg) !important;
}
.contributions-view .back-to-content {
  font-size: 12px;
  cursor: pointer;
  color: var(--text-color);
}
.contributions-view .page-title {
  font-weight: 550;
}
.contributions-view td {
  border-top: 1px solid var(--table-border-color);
}

.pagination-container {
  margin-top: 12px;
}